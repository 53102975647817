export class ResponseErrData {
  message = ''
  code = ''
  hint = ''
  details = ''

  constructor(fields: Partial<ResponseErrData>) {
    Object.assign(this, fields)
  }
}



export class ResponseErrDataV2 {
  code = ''
  desc: {
    en: string
    id: string
    hint?: string
    status?: string
  } = {
    en: '',
    id: '',
    hint: '',
    status: ''
  }
  message: {
    en: string
    id: string
  } = {
    en: '',
    id: ''
  }
  errorLocation = ''
  statusCode = 0

  constructor(fields: Partial<ResponseErrDataV2>) {
    Object.assign(this, fields)
  }
}

export class CustomError extends Error {
  message: string
  code: string
  hint: string

  constructor(data: ResponseErrData) {
    super(data.message)
    console.error(`${data.code}_${data.hint}: ${data.message}`)
    this.message = data.message
    this.code = data.code
    this.hint = data.hint
  }
}

export class ApiError {
  httpCode = 400
  httpMessage = ''
  httpResponseData: any = ''

  constructor(fields: Partial<ApiError>) {
    Object.assign(this, fields)
  }
}
