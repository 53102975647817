import { RouteRecordRaw } from 'vue-router'

export const buyerRoutes: RouteRecordRaw[] = [
  {
    path: '/orders',
    component: () => import('@/app/layouts/buyer-layout.vue'),
    children: [
      {
        path: ':id',
        name: 'old-order-detail', // Route for handling old orders
        component: () => import('@/app/views/buyer-page/order.vue'),
      },
      {
        path: ':id/waiting-payment',
        name: 'waiting-payment',
        component: () => import('@/app/views/buyer-page/order.vue'),
      },
      {
        path: ':id/thankyou',
        name: 'thankyou',
        component: () => import('@/app/views/buyer-page/order.vue'),
      },
      {
        path: ':id/detail',
        name: 'order-buyer-detail',
        component: () => import('@/app/views/buyer-page/order.vue'),
      },
      {
        path: ':id/payment-status',
        name: 'payment-status',
        component: () => import('@/app/views/buyer-page/order.vue'),
      },
      {
        path: '',
        name: 'order-buyer-all',
        component: () => import('@/app/views/buyer-page/all-orders.vue'),
      },
      {
        path: 'faq',
        name: 'faq-buyer',
        component: () => import('@/app/views/buyer-page/faq.vue'),
      },
      {
        path: 'login',
        name: 'login-buyer',
        component: () => import('@/app/views/buyer-page/components/login-buyer-form.vue'),
      },
    ],
  },
]
