import * as Rox from 'rox-browser'
import { RoxNumber, RoxString } from 'rox-browser'

export const flags = {
  paymentSetting: new Rox.Flag(false),
  formRegTransfer: new RoxString(''),
  intervalSeller: new RoxNumber(60),
  csWaPhoneNumber: new RoxString('62811816185'),
  masterclassUrl: new RoxString(''),
  revamp_cart_enable: new Rox.Flag(true),
  urlLocalDebug: new RoxString(''),
  keepOrderGuideUrl: new RoxString(''),
}

export async function initRollout() {
  const options = {
    configurationFetchedHandler: () => ({}),
  }

  // Register the flags with Rollout
  Rox.register('diklik', flags)

  // Setup the Rollout key
  await Rox.setup(import.meta.env.VITE_APP_ROLLOUT_KEY || '', options)
}
