export const socialMediaRegexUserAgent = {
  'facebook': /((?:fban\/fbios|fb_iab\/fb4a)(?!.+fbav)|;fbav\/([\w\.]+);)/i,
  'tiktok': /musical_ly(?:.+app_?version\/|_)([\w\.]+)/i,
  'instagram': /(instagram)[\/ ]([-\w\.]+)/i,
  'snapchat': /(snapchat)[\/ ]([-\w\.]+)/i,
  'twitter': /(Twitter ([\w\s\.]+)\/([\w\.]+)|\bTwitter)/i
}

export const regexBrowserUserAgent = {
  'chrome': /(?:Chrome|CriOS)\/(\d+)/,
  'firefox': /(?:Firefox|FxiOS)\/(\d+)/,
  'safari': /(?:Version|Ver\/)(\d+)/,
  'ie': /(?:MSIE |Trident\/.*; rv:)(\d+)/,
  'edge': /(?:Edge|Edg|EdgiOS|EdgA)\/(\d+)/,
  'opera': /(?:OPR|Opera)\/(\d+)/,
  'ucbrowser': /(?:\buc? ?browser|(?:juc.+)ucweb)[\/ ]?([\w\.]+)/i,
  'samsung':  /samsungbrowser\/([\w\.]+)/i,
  'mibrowser': /miuibrowser\/([\w\.]+)/i,
  'vivo': /(oppo|oculus|sailfish|huawei|vivo)browser\/([\w\.]+)/i,
  'safaribrowser': /(?=.*Safari)(?!.*(?:Chrome|Edge|CriOS)).*Version/,
  'heytapbrowser': /HeyTapBrowser/i,
  'slack': /Slackbot-LinkExpanding|Slackbot|^Slack\//i,
}

const detectBrowser = () => {
  for (const [key, value] of Object.entries(regexBrowserUserAgent)) {
    const match = navigator.userAgent.match(value)
    if (match) {
      return `${key}=${match.join('; ')}`
    }
  }
  return 'others'
}

const detectSocialMedia = () => {
  for (const [key, value] of Object.entries(socialMediaRegexUserAgent)) {
    const match = navigator.userAgent.match(value)
    if (match) {
      return `${key}=${match.join('; ')}`
    }
  }

  return 'not-included'
}

const detectIfDesktopOrAndroidOrIos = (): 'android' | 'ios' | 'desktop' => {
  const ua = navigator.userAgent
  const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
  const isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

  if (isAndroid) {
    return 'android'
  }

  if (isIOS) {
    return 'ios'
  }

  return 'desktop'
}

const detectScreenSize = (): string => {
  let width = 0

  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }

  if (width < 500) {
    return `mobile=${width}px`
  }

  // Small Desktop, Tablets, Horizontal Phones
  if (width > 500 && width < 1200) {
    return `tablet=${width}px`
  }

  //  Large Desktop, Horizontal Tablets
  return `desktop=${width}px`
}

const getOsWindowVersion11Or10Or8Or7 = (): string => {
  const userAgent = navigator.userAgent
  const os = userAgent.match(/Windows NT (\d+).(\d+)/)
  if (os) {
    return `windows=${os[1]}.${os[2]}`
  }

  return 'others'
}

const getOsWindowsVersionOrMacVersionOrLinuxVersion = (): string => {
  const userAgent = navigator.userAgent
  const os = userAgent.match(/Windows NT (\d+).(\d+)/)
  if (os) {
    return `windows=${os[1]}.${os[2]}`
  }

  const mac = userAgent.match(/Mac OS X (\d+).(\d+)/)
  if (mac) {
    return `mac=${mac[1]}.${mac[2]}`
  }

  const linux = userAgent.match(/Linux (\d+).(\d+)/)
  if (linux) {
    return `linux=${linux[1]}.${linux[2]}`
  }

  return 'others'
}

export const detectUserAgent = (): {
  browser: string
  'social-media': string
  device: 'android' | 'ios' | 'desktop'
  'screen-size': string,
  'os-detail': string
} => {
  return {
    browser: detectBrowser(),
    'social-media': detectSocialMedia(),
    'device': detectIfDesktopOrAndroidOrIos(),
    'screen-size': detectScreenSize(),
    'os-detail': getOsWindowsVersionOrMacVersionOrLinuxVersion(),
  }
}
