<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import isFacebookInAppIos from '@/infrastructures/misc/isFacebookApp'

export default defineComponent({
  name: 'App',
  setup() {
    const elBlankSpace: any = document.getElementById('blank-space-fb')
    if (isFacebookInAppIos()) {
      if (!elBlankSpace) return
      elBlankSpace.style.height = '50vh'
      elBlankSpace.style.display = 'flex'
      elBlankSpace.style.alignItems = 'center'
      elBlankSpace.style.justifyContent = 'center'
    }
  },
})
</script>
