import { createClient, SupabaseClient } from '@supabase/supabase-js'
import { container, injectable } from 'tsyringe'
import { detectUserAgent } from '@/infrastructures/misc/detectBrowserIInfo'
import { CustomError } from '@/infrastructures/dependencies/common/domain/api-error'
import TracingAndLog, { BreadcrumbTracingLog } from '@/infrastructures/misc/tracingAndLog'

const customFetch = async (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> =>  {
  try {
    const response = await fetch(input, init)
    if (!response.ok) {
      const getCloneResponse = response.clone()
      const errorResponse = await getCloneResponse.json()
      if (response.status >= 500) {
        const customError = new CustomError({
          code: String(response.status),
          hint: 'error_from_supabase_client_fetch_api',
          message: `${response.url} - ${response.status}`,
          details: response.statusText,
        })
        if ('message' in errorResponse) {
          customError.message = `status_${response.status} - ${errorResponse.message}`
        }

        TracingAndLog.setExtraData('errorResponse', errorResponse)
        TracingAndLog.captureException(customError)
      } else {
        const objBreadcrumb: BreadcrumbTracingLog = {
          message: `${response.url} - ${response.statusText}`,
          type: 'http_error',
          category: 'xhr',
          level: 'error',
        }
        objBreadcrumb.data = {
          ...errorResponse,
          url: response.url,
          status_code: response.status,
        }
        TracingAndLog.addBreadcrumb(objBreadcrumb)
      }
    }
    return response
  } catch (error) {
    if (error instanceof Error && !error.message.includes('Failed to fetch')) {
      TracingAndLog.captureException(error)
    }
    return Promise.reject(error)
  }
}

const client = createClient(import.meta.env.VITE_APP_API_URL, import.meta.env.VITE_PUBLIC_ANON_KEY, {
  global: {
    headers: {
      ...detectUserAgent(),
    },
    fetch: customFetch,
  },
})

const supabaseClient = () => client

@injectable()
export default class SupabaseService {
  public supabase: SupabaseClient

  constructor() {
    this.supabase = supabaseClient()
    container.register<SupabaseService>(SupabaseService, { useClass: SupabaseService })
  }

  public async upload(bucket: string, path: string, file: File) {
    return await this.supabase.storage.from(bucket).upload(path, file)
  }

  public async removeImage(bucket: string, oldFolder: string, newFolder: string) {
    return await this.supabase.storage.from(bucket).move(oldFolder, newFolder)
  }
}
