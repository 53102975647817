import * as Sentry from '@sentry/vue'

export interface UserTracingLog {
  [key: string]: unknown;

  id?: string | number
  ip_address?: string
  email?: string
  username?: string
  segment?: string
}

export interface BreadcrumbTracingLog {
  type?: string
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
  category?: 'xhr' | 'others' | 'ui.click' | 'ui.input' | 'console' | 'navigation' | 'http_response'
  message?: string
  data?: Record<string, any>
}

export interface BreadcrumbHintTracingLog {
  [key: string]: unknown;
}

export default class TracingAndLog {
  private static readonly _libTracingAndLog = Sentry

  static setTag(key: string, value: string): void {
    this._libTracingAndLog.setTag(key, value)
  }

  static setExtraData<T = Record<string, unknown>>(key: string, extraData: T): T {
    this._libTracingAndLog.setExtra(key, extraData)
    return extraData
  }

  static setUserData(userData: UserTracingLog) {
    this._libTracingAndLog.setUser(userData)
  }

  static captureException(error: Error | unknown) {
    this._libTracingAndLog.withScope((scope) => {
      if (error instanceof Error) {
        scope.setExtra('originalError', error.message)
      }
      this._libTracingAndLog.captureException(error)
    })
  }

  static addBreadcrumb(breadcrumb: BreadcrumbTracingLog, hint?: BreadcrumbHintTracingLog) {
    this._libTracingAndLog.addBreadcrumb(breadcrumb, hint)
  }
}
